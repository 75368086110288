<template>
	<main>
		<div class="project-box">
			<div class="list">
				<a-link :to="'/sentry/project/dashboard?project_id='+item.id" class=" item" v-for="(item,index) in list" :key="index">
					<div class="title-box">
						<div class="title" :class="['icon-'+item.type]">{{item.title}} <i>{{timeToString(item.create_time,'YYYY-MM-DD')}}</i></div>
						<div class="status"><el-tag :type="statusText(item) === '正在运行' ? 'primary' : 'danger'">{{statusText(item)}}</el-tag></div>
					</div>
					<div class="stat-box">
						<div class="li"><div class="num">{{item.stat.all}}</div><div class="tit">总事件</div></div>
						<div class="li"><div class="num">{{item.stat.today}}</div><div class="tit">今日事件</div></div>
						<div class="li"><div class="num">{{item.stat.yesterday}}</div><div class="tit">昨日事件</div></div>
					</div>
					<div class="detail-box">
						<div class="li" v-for="(v,i) in type_list" :key="i">{{ v }}：<i>{{ statDetail(item,v) }}</i></div>
					</div>
				</a-link>
			</div>
			<a-empty class="empty-box" :status="list_status" @click="getProjectList"/>
		</div>
	</main>
</template>
<script>
import {project} from "@/api/sentry";
import {sentry} from "@/config";
import moment from "moment";
import {timeToString} from "@/service/admin/common";
export default {
	data(){
		return {
			type_list:sentry.type,
			page:1,
			limit:9,
			list:[],
			list_status:0
		}
	},
	mounted() {
		this.getProjectList();
	},
	methods:{
		timeToString,
		statusText(item){
			const now_time = moment().unix();
			return item.status === 1 ? (item.end_time > 0 && item.end_time <= now_time) ? ' 已过期' : '正在运行' : '已关闭'
		},
		statDetail(item,type){
			const detail = item?.stat_detail || [];
			const res = detail.find((v,i)=>{
				return v.type === type;
			})
			return res?.num || 0
		},
		getProjectList(){
			this.list_status = 0;
			project.all({
				page:this.page,
				limit:this.limit
			}).then((res)=>{
				this.list = this.list.concat(res.list);
				if(this.list.length <= 0){
					this.list_status = 3;
				}else if(this.list.length >= res.count){
					this.list_status = 2;
				}else{
					this.list_status = 1;
					this.page = this.page+1;
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.project-box{padding: 15px;
	.list{display: grid;grid-gap: 15px;grid-template-columns:repeat(4, 1fr);
		.item{padding: 15px;box-shadow: 0 0 20px 5px rgba(0,0,0,.1);
			.title-box{display: flex;align-items: center;justify-content:space-between;
				.title{flex: 1;margin-right: 15px;font-weight: bold;padding-left: 20px;
					i{font-weight: normal;font-size: 12px;color:#666;}
				}
				.title.icon-js{background: url("~@/assets/sentry/image/js.png") no-repeat left center;background-size: 16px;}
				.title.icon-vue{background: url("~@/assets/sentry/image/vue.png") no-repeat left center;background-size: 16px;}
				.title.icon-react{background: url("~@/assets/sentry/image/react.png") no-repeat left center;background-size: 16px;}
				.title.icon-wxapp{background: url("~@/assets/sentry/image/wxapp.png") no-repeat left center;background-size: 16px;}
			}
			.stat-box{margin-top: 15px;display: flex;
				.li{flex: 1;text-align: center;
					.num{font-size: 40px;font-weight: bold;color:$admin_main;}
				}
			}
			.detail-box{margin-top: 15px; display: flex;flex-wrap: wrap;color:#666;
				.li{width: calc((100% - 15px)/2);margin-right: 15px;line-height: 1.5;
					i{font-weight: bold;}
				}
				.li:nth-child(2n){margin-right: 0;}
			}
		}
		.item:nth-child(3n){margin-right: 0;}
	}
	.empty-box{margin: 20px 0;}
}
@media screen and (max-width: 1920px){
	.project-box{
		.list{grid-template-columns: repeat(3,1fr);}
	}
}
@media screen and (max-width: 1230px){
	.project-box{
		.list{grid-template-columns: repeat(2,1fr);}
	}
}
</style>
